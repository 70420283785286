export const CHANGE_THEME_MODE = "CHANGE_THEME_MODE";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const VIEW_USER_DETAIL = "VIEW_USER_DETAIL";
export const UPDATE_PROFILE_DETAIL = "UPDATE_PROFILE_DETAIL";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const GET_STATICS = "GET_STATICS";
export const CREATE_PUBLIC_ROOM = "CREATE_PUBLIC_ROOM";
export const GET_PUBLIC_ROOMS = "GET_PUBLIC_ROOMS";
export const GET_SUPPORTS = "GET_SUPPORTS";
export const GET_SUPPORT_DETAILS = "GET_SUPPORT_DETAILS";
export const GET_SUPPORT_CHAT = "GET_SUPPORT_CHAT";
export const DASHBOARD = "DASHBOARD";
export const CREATE_ANNOUNCEMENT = "CREATE_ANNOUNCEMENT";
export const GET_ALL_ANNOUNCEMENTS = "GET_ALL_ANNOUNCEMENTS";
export const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT";
export const GET_VERIFICATION_USERS = "GET_VERIFICATION_USERS";
export const VERIFY_USERS = "VERIFY_USERS";
export const GET_REPORTS = "GET_REPORTS";
export const DELETE_REPORT = 'DELETE_REPORT';


export const CREATE_CDL = "CREATE_CDL"
export const GET_ALL_CDLS = "GET_ALL_CDLS"
export const UPDATE_CDL = "UPDATE_CDL"
export const CREATE_TRUCK = "CREATE_TRUCK"
export const GET_ALL_TRUCK = "GET_ALL_TRUCK"
export const UPDATE_TRUCK = "UPDATE_TRUCK"
export const CREATE_TITLE = "CREATE_TITLE"
export const GET_ALL_TITLE = "GET_ALL_TITLE"
export const UPDATE_TITLE = "UPDATE_TITLE"
export const CREATE_INTEREST = "CREATE_INTEREST"
export const GET_ALL_INTEREST = "GET_ALL_INTEREST"
export const UPDATE_INTEREST = "UPDATE_INTEREST"
export const CREATE_TOPICS = "CREATE_TOPICS"
export const GET_ALL_TOPICS = "GET_ALL_TOPICS"
export const UPDATE_TOPICS = "UPDATE_TOPICS"