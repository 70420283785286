import { Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createAnnouncement,
  createPublicRoom,
  getStatics,
  updateAnnouncement,
  updateCdlTypes,
  updateInterest,
  updateTitle,
  updateTopics,
  updateTruckType,
} from "../../../store/actions/AdminAction";
import { toast } from "react-toastify";

const UpdateDropdownTypesModal = ({ viewVal, setViewVal, editAnnouncement, title,name }) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState("");

  var initFormik = {
    [name]: editAnnouncement?.[name],
    id:editAnnouncement?._id
  }
  if(name == 'roomcategory'){
    initFormik.image= editAnnouncement?.image
  }
  return (
    <Modal
      show={viewVal}
      onHide={() => {
        setViewVal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal"
    >
 
      <Modal.Body>
        <h4 className="text-center mb-0 delete_content">
        Update {title}
        </h4>

        <div className="card-body">
          <div className="basic-form">
            <Formik
              initialValues={initFormik}
              validate={(values) => {
                const errors = {};
                if (!values[name]) {
                  errors[name] = "Required*";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting}) => {
           
                if(name == "cdltype"){
                  dispatch(updateCdlTypes(values))
                }else if(name == "trucktype"){
                  dispatch(updateTruckType(values));
                }else if(name == "title"){
                  dispatch(updateTitle(values));
                }else if(name == "interest"){
                  dispatch(updateInterest(values));
                }else if(name == "roomcategory"){
                  const formData = new FormData();

                  for (const key in values) {
                      formData.append(key, values[key]);
                  }
                  dispatch(updateTopics(formData));
                }
                    
                setViewVal(false);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleReset,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label>Title</label>
                      <input
                        type="text"
                        name={name}
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values[name]}
                      />
                      {errors[name] && (
                        <span class="formik-errors">{errors[name]}</span>
                      )}
                    </div>

                    {
                      name == 'roomcategory' && <div className="form-group mb-3 col-md-12">
                      <input
                        name="image"
                        onChange={(e) => {
                          setFieldValue(
                            `image`,
                            e.target.files[0]
                          );
                          setPreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        type="file"
                        accept="image/*"
                        className="blog-upload-input"
                        placeholder="Upload Image"
                      />

                      <div className="blog-upload-img form-control-new">
                        {preview ? <img src={preview} /> : 
                        values?.image && <img src={process.env.REACT_APP_FILE_BASE_URL + `/${values?.image}`} />
                        }
                       
                      </div>
                    </div>
                    }
                  </div>

                  <div className="d-flex justify-content-center delete_acc_btns">
                    <button
                      // onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
                    >
                     Update
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setViewVal(false);
                      }}
                      className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDropdownTypesModal;
