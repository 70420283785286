import { toast } from "react-toastify";
import API from "../../services/Api";
import {
  CHANGE_THEME_MODE,
  CREATE_PUBLIC_ROOM,
  GET_ALL_USERS,
  GET_PUBLIC_ROOMS,
  GET_STATICS,
  START_LOADING,
  STOP_LOADING,
  UPDATE_PROFILE_DETAIL,
  VIEW_USER_DETAIL,
  GET_SUPPORTS,
  GET_SUPPORT_DETAILS,
  GET_SUPPORT_CHAT,
  DASHBOARD,
  CREATE_ANNOUNCEMENT,
  GET_ALL_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT,
  GET_VERIFICATION_USERS,
  VERIFY_USERS,
  GET_REPORTS,
  DELETE_REPORT,
  UPDATE_INTEREST,
  GET_ALL_INTEREST,
  CREATE_INTEREST,
  UPDATE_TITLE,
  GET_ALL_TITLE,
  CREATE_TITLE,
  UPDATE_TRUCK,
  GET_ALL_TRUCK,
  CREATE_TRUCK,
  UPDATE_CDL,
  GET_ALL_CDLS,
  CREATE_CDL,
  CREATE_TOPICS,
  GET_ALL_TOPICS,
  UPDATE_TOPICS,
} from "../Constant/constant";

export const dashboardApi = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get("/dashboard");
    if (res?.data?.success) {
      dispatch({
        type: DASHBOARD,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const changeThemeMode = (themeMode) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_THEME_MODE,
      payload: themeMode,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAllUsers = (page, limit, search, data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getUsers?page=${page == undefined ? 1 : page}&limit=${limit}&search=${
        search ? search : undefined
      }`,
      data
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_USERS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const viewUserDetail = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/getUserDetails?user_id=${id}`);
    if (data?.success) {
      dispatch({
        type: VIEW_USER_DETAIL,
        payload: data.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const updateUserProfile = (data) => async (dispatch) => {
  try {
    const res = await API.put("/editUserDetails", data);
    if (res?.data.success) {
      dispatch({
        type: UPDATE_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const deleteUserAcc = (id, navigate) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const response = await API.delete(`/deleteUserAccount?user_id=${id}`);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
      navigate("/user-management");
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(response?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const changePassword = (data) => async (dispatch) => {
  const res = await API.put("/changeUserPass", data);
  if (res?.data.success) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};
export const deactivateAccount = (data) => async (dispatch) => {
  try {
    const res = await API.put("/deactivateUserAccount", data);
    if (res?.data.success) {
      dispatch(viewUserDetail(data.userId));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getVerificationUsers =
  (page, limit, search) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const res = await API.get(
        `/getVerificationUsers?page=${page == undefined ? 1 : page}&limit=${limit}&search=${
          search ? search : undefined
        }`
      );
      if (res?.data?.success) {
        dispatch({
          type: GET_VERIFICATION_USERS,
          payload: res?.data?.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(res?.data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };

export const verifyUser = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/verifyUser", data);
    if (res?.data?.success) {
      dispatch({
        type: VERIFY_USERS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getStatics = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get("/getStatics");
    if (res?.data?.success) {
      dispatch({
        type: GET_STATICS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getPublicRooms = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getPublicRooms?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_PUBLIC_ROOMS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const createPublicRoom = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createPublicRoom", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_PUBLIC_ROOM,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getPublicRooms(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getSupports = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getSupports?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_SUPPORTS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const getSupportDetails = (support_id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(`/getSupportDetails?support_id=${support_id}`);
    if (res?.data?.success) {
      dispatch({
        type: GET_SUPPORT_DETAILS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const sendSupportMesage =
  (support_id, messageObject) => async (dispatch) => {
    try {
      const res = await API.post("/sendAdminSupportMessage", {
        message: messageObject,
        support_id,
      });
      if (res?.data?.success) {
        dispatch({
          type: GET_SUPPORT_DETAILS,
          payload: res?.data?.data,
        });
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

export const changeSupportStatus = (support_id, status) => async (dispatch) => {
  try {
    const res = await API.post("/changeSupportStatus", {
      status,
      support_id,
    });
    if (res?.data?.success) {
      dispatch(getSupports());
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const getAnnouncements = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getAllAnnouncement?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_ANNOUNCEMENTS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const createAnnouncement = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createAnnouncement", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_ANNOUNCEMENT,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getAnnouncements(1, 7, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const deleteAnnoncement = (data) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteAnnouncement?id=${data}`);
    if (res?.data.success) {
      dispatch(getAnnouncements(1, 7, undefined));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const updateAnnouncement = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.put("/updateAnnouncement", data);
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_ANNOUNCEMENT,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getAnnouncements(1, 7, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};


export const getReports = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/reportList?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_REPORTS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const changeReportStatus = (report_id, status) => async (dispatch) => {
  try {
    const res = await API.post("/changeReportStatus", {
      status,
      report_id,
    });
    if (res?.data?.success) {
      toast.success(res?.data?.message)
      //dispatch(getReports());
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteReport = (id) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteReport?id=${id}`);
    if (res?.data?.success) {
      toast.success(res?.data?.message)
      dispatch({
        type: DELETE_REPORT,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const createCdlTypes = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createCdlTypes", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_CDL,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getCdlTypes(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getCdlTypes = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getCdlTypes?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_CDLS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const updateCdlTypes = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.put("/updateCdlTypes", data);
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_CDL,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getCdlTypes(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const deleteCdlTypes = (data) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteCdlTypes?id=${data}`);
    if (res?.data.success) {
      dispatch(getCdlTypes(1, 10, undefined));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const createTruckType = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createTruckType", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_TRUCK,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getTruckType(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getTruckType = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getTruckType?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_TRUCK,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const updateTruckType = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.put("/updateTruckType", data);
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_TRUCK,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getTruckType(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const deleteTruckType = (data) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteTruckType?id=${data}`);
    if (res?.data.success) {
      dispatch(getTruckType(1, 10, undefined));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const createTitle = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createTitle", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_TITLE,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getTitle(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getTitle = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getTitle?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_TITLE,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const updateTitle = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.put("/updateTitle", data);
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_TITLE,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getTitle(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const deleteTitle = (data) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteTitle?id=${data}`);
    if (res?.data.success) {
      dispatch(getTitle(1, 10, undefined));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const createInterest = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createInterest", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_INTEREST,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getInterest(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getInterest = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getInterest?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_INTEREST,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const updateInterest = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.put("/updateInterest", data);
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_INTEREST,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getInterest(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const deleteInterest = (data) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteInterest?id=${data}`);
    if (res?.data.success) {
      dispatch(getInterest(1, 10, undefined));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const createTopics = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createTopics", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_TOPICS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getTopics(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getTopics = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getTopics?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_TOPICS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const updateTopics = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.put("/updateTopics", data);
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_TOPICS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getTopics(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const deleteTopics = (data) => async (dispatch) => {
  try {
    const res = await API.delete(`/deleteTopics?id=${data}`);
    if (res?.data.success) {
      dispatch(getInterest(1, 10, undefined));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};