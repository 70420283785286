import { Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createPublicRoom,
  getStatics,
} from "../../../store/actions/AdminAction";
import { toast } from "react-toastify";
const CreatePublicRoomModal = ({ viewVal, setViewVal }) => {
  const dispatch = useDispatch();
  const [roomCategories, setRoomCategories] = useState();
  const roomtypes = useSelector(
    (state) => state.adminReducer.statics?.roomtypes
  );
  const roomcategories = useSelector(
    (state) => state.adminReducer.statics?.roomcategories
  );

  // const handleCategorySelect = () => {

  // }

  useEffect(() => {
    dispatch(getStatics());
  }, []);

  return (
    <Modal
      show={viewVal}
      onHide={() => {
        setViewVal(false);
        setRoomCategories();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal"
    >
      {/* <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {modalTitle[modalName]}
            </Modal.Title>
          </Modal.Header> */}
      <Modal.Body>
        <h4 className="text-center mb-0 delete_content">Create Public Room</h4>

        <div className="card-body">
          <div className="basic-form">
            <Formik
              initialValues={{
                roomname: "",
                description: "",
                roomtype: "",
                // roomcategory: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.roomname) {
                  errors.roomname = "Required*";
                }
                if (!values.description) {
                  errors.description = "Required*";
                }
                // if (!values.roomcategory) {
                //   errors.roomcategory = "Required*";
                // }
                if (!values.roomtype) {
                  errors.roomtype = "Required*";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (roomCategories?.length < 1) {
                  toast.warning("Please select Room Categories");
                } else {
                  const roomcats = roomCategories?.map(
                    (roomcat) => roomcat.value
                  );
                  // values.roomcategory = JSON.stringify(roomcats);
                  values.roomcategory = roomcats

                  dispatch(createPublicRoom(values));
                  setViewVal(false);
                  setRoomCategories();
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label>Room Name</label>
                      <input
                        type="text"
                        name="roomname"
                        className="form-control"
                        placeholder="Room Name"
                        onChange={handleChange}
                        value={values.roomname}
                      />
                      {errors.roomname && (
                        <span class="formik-errors">{errors.roomname}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Description</label>
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.description}
                      />
                      {errors.description && (
                        <span class="formik-errors">{errors.description}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Category</label>
                      <Select
                        onChange={(e) => setRoomCategories(e)}
                        isMulti
                        options={roomcategories}
                        value={roomCategories}
                      />
                      {/* <select
                        defaultValue={"option"}
                        id="inputState"
                        className="form-control"
                        onChange={handleChange}
                        name="roomcategory"
                      >
                        <option value="option" disabled hidden>
                          Select
                        </option>
                        {roomcategories?.map((roomcategory) => (
                          <option value={roomcategory._id}>
                            {roomcategory.roomcategory}
                          </option>
                        ))}
                      </select> */}
                      {errors.roomcategory && (
                        <span class="formik-errors">{errors.roomcategory}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Type</label>
                      <select
                        defaultValue={"option"}
                        id="inputState"
                        className="form-control"
                        onChange={handleChange}
                        name="roomtype"
                      >
                        <option value={"option"} disabled hidden>
                          Select
                        </option>
                        {roomtypes?.map((roomtype) => (
                          <option value={roomtype._id}>
                            {roomtype.roomtype}
                          </option>
                        ))}
                      </select>
                      {errors.roomtype && (
                        <span class="formik-errors">{errors.roomtype}</span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center delete_acc_btns">
                    <button
                      // onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setViewVal(false);
                        setRoomCategories();
                      }}
                      className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatePublicRoomModal;
