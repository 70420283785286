import { Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createAnnouncement,
  createPublicRoom,
  getStatics,
} from "../../../store/actions/AdminAction";
import { toast } from "react-toastify";
const AnnouncemetModal = ({ viewVal, setViewVal }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={viewVal}
      onHide={() => {
        setViewVal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal"
    >
      <Modal.Body>
        <h4 className="text-center mb-0 delete_content">Create Announcement</h4>

        <div className="card-body">
          <div className="basic-form">
            <Formik
              initialValues={{
                title: "",
                description: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.title) {
                  errors.title = "Required*";
                }
                if (!values.description) {
                  errors.description = "Required*";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(createAnnouncement(values));
                setViewVal(false);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleReset,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label>Title</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.title}
                      />
                      {errors.title && (
                        <span class="formik-errors">{errors.title}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Description</label>
                      <textarea
                        rows="3"
                        className="form-control"
                        type="text"
                        name="description"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.description}
                      ></textarea>
                      {errors.description && (
                        <span class="formik-errors">{errors.description}</span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center delete_acc_btns">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setViewVal(false);
                      }}
                      className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AnnouncemetModal;
