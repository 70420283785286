import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncements, getCdlTypes } from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
import AnnouncemetModal from "../components/modals/AnnouncemetModal";
import DeleteModal from "../components/modals/DeleteModal";
import DropdownTypesModal from "../components/modals/DropdownTypesModal";
import UpdateDropdownTypesModal from "../components/modals/UpdateDropdownTypesModal";


const Cdl = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL + "/";
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const annoncement = useSelector(
    (state) => state?.adminReducer?.allCdl
  );
  const totalAnnoncement = useSelector(
    (state) => state?.adminReducer?.totalCdl
  );
  const lastIndex = page * limit;
  const npage = Math.ceil(totalAnnoncement / limit);
  const number = npage ? [...Array(npage + 1).keys()].slice(1) : [1];
  const [viewVal, setViewVal] = useState(false);
  const [viewDeleteModal, setViewDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editAnnouncement, setEditAnnouncement] = useState();
  useEffect(() => {
    dispatch(getCdlTypes(page, limit, search));
  }, [page, limit, search]);

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const invite = useRef();
  const employe = useRef();

  // const temp = 13;
  //   let arr=[2,3,4,5,6,7];
  //   let sum=0;
  //   for(let i=0;i<arr?.length;i++){
  //     sum+=arr[i];

  //   }
  // console.log(arr.slice(1,3),"slice----slice");

  return (
    <Layout>
      <MainPagetitle
        mainTitle="CDL Types"
        pageTitle={"CDL Types"}
        parentTitle={"Home"}
      />
      {deleteId && (
        <DeleteModal
          viewVal={viewDeleteModal}
          setShowVal={setViewDeleteModal}
          modalName={"cdlDelete"}
          id={deleteId}
        />
      )}
      <UpdateDropdownTypesModal title="CDL Type"  name="cdltype"  viewVal={updateModal} setViewVal={setUpdateModal} editAnnouncement={editAnnouncement} />
      <DropdownTypesModal viewVal={viewVal} setViewVal={setViewVal} title="CDL Type" name="cdltype" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">CDL Types</h4>
                    <div className="srearch-btn">
                      <div className="tbl-caption d-flex justify-content-end text-wrap align-items-center pl-0-btn">
                        <button
                          className="btn btn-primary"
                          onClick={() => { setViewVal(true); setEditAnnouncement() }}
                        >
                          Create
                        </button>
                        <div className="searchbarpublic">
                          <Search setSearch={setSearch} setPage={setPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer h-fixed-505"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {annoncement?.length > 0 ?
                          annoncement?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center">
                                  {/* <img src={item.image} className="avatar avatar-md rounded-circle" alt="" /> */}
                                  <div className="ms-2">
                                    <p className="mb-0 text-start font-w500">
                                      {item.cdltype}
                                    </p>
                                    {/* <span>demo@gmail.com</span> */}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="mb-0 font-w500">
                                  {" "}
                                  {moment(item?.createdAt).format("Do MMMM YYYY")}
                                </p>
                              </td>
                              <td>
                                <div className="action-button">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-icon-xxs"
                                    onClick={() => { setEditAnnouncement(item); setUpdateModal(true) }}
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </button>{" "}
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-icon-xxs"
                                    onClick={() => {
                                      setDeleteId(item?._id);
                                      setViewDeleteModal(true);
                                    }}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )) : <td colspan="10">
                            <p className="no_content_table">No CDL type yet</p>
                          </td>}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing{" "}
                        {totalAnnoncement == 0 ? 0 : lastIndex - limit + 1} to{" "}
                        {totalAnnoncement < lastIndex
                          ? totalAnnoncement
                          : lastIndex}{" "}
                        of {totalAnnoncement} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${page === n ? "current" : ""
                                } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />
    </Layout>
  );
};

export default Cdl;
