export const modalTitle = {
  userAccount: "Delete User Account",
  driverAccount: "Delete Driver Account",
  deactivateAccount: "Deactivate User Account",
};
export const modalBody = {
  userAccount: "Are you sure you want to delete this account?",
  deactivateAccount: `Are you sure you want to Activate/Deactivate this account?`,
  announcementDelete:"Are you sure you want to delete this annoncement?",
  cdlDelete: "Are you sure you want to delete this CDL Type?",
  truckDelete: "Are you sure you want to delete this Truck Type?",
  titleDelete: "Are you sure you want to delete this Title?",
  interestDelete: "Are you sure you want to delete this Interest?",
  topicsDelete: "Are you sure you want to delete this Topic?"
};
