import {
  CHANGE_THEME_MODE,
  GET_ALL_USERS,
  GET_PUBLIC_ROOMS,
  GET_STATICS,
  GET_SUPPORTS,
  VIEW_USER_DETAIL,
  GET_SUPPORT_DETAILS,
  DASHBOARD,
  GET_ALL_ANNOUNCEMENTS,
  GET_VERIFICATION_USERS,
  VERIFY_USERS,
  GET_REPORTS,
  DELETE_REPORT,
  GET_ALL_CDLS,
  GET_ALL_TRUCK,
  GET_ALL_TITLE,
  GET_ALL_INTEREST,
  GET_ALL_TOPICS,
} from "../Constant/constant";

let initialstate = {
  userList: null,
  totalUser: null,
  verifyUserList: null,
  totalVerifyUser: null,
  userDetail: null,
  statics: null,
  publicRooms: null,
  totalRooms: null,
  themeMode: true,
  supportList: null,
  totalSupports: null,
  supportDetails: null,
  supportChat: null,
  dashboard: null,
  totalAnnoncement: null,
  allAnnoncement: null,
  reportList: [],
  totalReports: null,

  totalCdl: null,
  allCdl: null,
  totalTruck: null,
  allTruck: null,
  totalTitle: null,
  allTitle: null,
  totalInterest: null,
  allInterest: null,
  totalTopics: null,
  allTopics: null,  
};
const AdminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case DASHBOARD:
      return {
        ...state,
        dashboard: action?.payload,
      };
    case CHANGE_THEME_MODE:
      return {
        ...state,
        themeMode: action?.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        userList: action?.payload?.users,
        totalUser: action?.payload?.totalUsers,
      };
    case GET_VERIFICATION_USERS:
      return {
        ...state,
        verifyUserList: action?.payload?.users,
        totalVerifyUser: action?.payload?.totalUsers,
      };

    case VERIFY_USERS:
      const userslist = [...state.verifyUserList];
      const updateduserlist = userslist?.map((user) =>
        user?._id == action?.payload?._id ? action?.payload : user
      );

      return {
        ...state,
        verifyUserList: updateduserlist,
      };

    case VIEW_USER_DETAIL:
      return {
        ...state,
        userDetail: action?.payload,
      };
    case GET_STATICS:
      const roomcategories = action?.payload?.roomcategories?.map((ob) => {
        return { label: ob.roomcategory, value: ob._id };
      });
      action.payload.roomcategories = roomcategories;
      return {
        ...state,
        statics: action?.payload,
      };
    case GET_PUBLIC_ROOMS:
      return {
        ...state,
        publicRooms: action?.payload?.rooms,
        totalRooms: action?.payload?.totalRooms,
      };
    case GET_SUPPORTS:
      return {
        ...state,
        supportList: action?.payload.supports,
        totalSupports: action?.payload.totalSupports,
      };
    case GET_SUPPORT_DETAILS:
      return {
        ...state,
        supportDetails: action?.payload,
      };
    case GET_ALL_ANNOUNCEMENTS:
      return {
        ...state,
        allAnnoncement: action?.payload?.users,
        totalAnnoncement: action?.payload?.totalUsers,
      };
    case GET_REPORTS:
      return {
        ...state,
        reportList: action?.payload.reports,
        totalReports: action?.payload.totalReports,
      };
    case DELETE_REPORT:
      console.log(action?.payload, "action?.payload");
      return {
        ...state,
        reportList: state.reportList?.filter((item) => item?._id != action?.payload?._id),
      };
    case GET_ALL_CDLS:
      return {
        ...state,
        allCdl: action?.payload?.users,
        totalCdl: action?.payload?.totalUsers,
      };
    case GET_ALL_TRUCK:
      return {
        ...state,
        allTruck: action?.payload?.users,
        totalTruck: action?.payload?.totalUsers,
      };
    case GET_ALL_TITLE:
      return {
        ...state,
        allTitle: action?.payload?.users,
        totalAnnoncement: action?.payload?.totalUsers,
      };
    case GET_ALL_INTEREST:
      return {
        ...state,
        allInterest: action?.payload?.users,
        totalInterest: action?.payload?.totalUsers,
      };
      case GET_ALL_TOPICS:
        return {
          ...state,
          allTopics: action?.payload?.users,
          totalTopics: action?.payload?.totalUsers,
        };

    default:
      return state;
  }
};
export default AdminReducer;
